<template>
  <v-dialog
    v-model="isVisibleEditBundleItemsDialog"
    persistent
  >
    <template>
      <v-card class="rounded-lg">
        <!-- selected items -->
        <div class="d-flex align-center justify-space-between px-6 py-6">
          <h3 class="font-weight-regular">
            {{ $t("setup.grouped_products.select_bundle_items") }}
          </h3>
          <v-spacer></v-spacer>
          <div>
            <v-icon @click="closeForm"> mdi-close </v-icon>
          </div>
        </div>
        <!-- selected items end -->
        <v-card-text>
          <h3 class="font-weight-regular mb-2">
            {{ $t("products_list") }}
          </h3>
          <v-row>
            <v-col
              cols="12"
              sm="3"
              md="3"
            >
              <v-text-field
                outlined
                dense
                hide-details
                class="mb-6"
                v-model="options.search"
                :label="$t('search')"
                :disabled="is_loading_product"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              sm="3"
              md="3"
            >
              <v-autocomplete
                outlined
                dense
                hide-details
                class="mb-6"
                clearable
                v-model="options.category_id"
                :items="leafCategories"
                item-text="name"
                item-value="id"
                :loading="isLoadingCategories"
                :label="$t('category')"
                :disabled="is_loading_product"
                @focus="getSubCategories"
              ></v-autocomplete>
            </v-col>
            <v-col
              cols="12"
              sm="3"
              md="3"
            >
              <v-text-field
                outlined
                dense
                hide-details
                type="number"
                hide-spin-buttons
                class="mb-6"
                v-model="options.qte"
                :label="$t('stock_volume')"
                :disabled="is_loading_product"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-data-table
            :headers="headers"
            :items="listProducts"
            :loading="isLoadingProducts"
            :server-items-length="productsMeta.totalItems"
            :options.sync="options"
            :items-per-page="10"
            :footer-props="{
              'items-per-page-options': [5, 10, 20],
              'disable-pagination': isLoadingProducts,
            }"
          >
            <template v-slot:item.image="{ item }">
              <v-img
                v-if="item.image"
                :src="item.image.url"
                max-height="50"
                max-width="50"
              />
              <v-img
                v-else
                src="@/assets/product.png"
                max-height="50"
                max-width="50"
              />
            </template>
            <template v-slot:item.actions="{ item }">
              <template v-if="item?.qte < 1 && !isItemSelected(item)">
                <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      @click="toggleAddedProduct(item)"
                      small
                      filled
                      v-bind="attrs"
                      v-on="on"
                    >
                      {{ $t("add_") }}
                    </v-btn>
                  </template>
                  <span>{{ $t("min_bundle_qte",{qte:item?.qte ?? 0}) }}</span>
                </v-tooltip>
              </template>
              <template v-else>
                <v-btn
                  @click="toggleAddedProduct(item)"
                  small
                  v-if="isItemSelected(item)"
                >
                  {{ $t("added") }}
                </v-btn>
                <v-btn
                  @click="toggleAddedProduct(item)"
                  small
                  filled
                  color="primary"
                  v-if="!isItemSelected(item)"
                >
                  {{ $t("add_") }}
                </v-btn>
              </template>
            </template>
          </v-data-table>
        </v-card-text>
        <template v-if="editedBundleItems?.length > 0">
          <div
            class="d-flex justify-end mb-8 me-4"
            style="min-width: 600px"
          >
            <div>
              <div class="mb-4">
                <h3 class="ms-4">
                  {{ $t("setup.grouped_products.selected_products") }}
                </h3>
                <v-divider class="my-0"></v-divider>
              </div>
              <div
                v-for="(item, i) in editedBundleItems"
                class="d-flex align-center my-2 mx-2"
                :key="`item-${i}`"
              >
                <v-img
                  v-if="item?.images.length"
                  :src="item?.images[0]?.url"
                  max-height="100"
                  max-width="100"
                  class="mx-2"
                />
                <v-img
                  v-else
                  src="@/assets/product.png"
                  max-height="50"
                  max-width="50"
                />
                <div class="me-4 pt-4">
                  <h4>
                    {{ item?.name }}
                  </h4>
                  <p>
                    ID:&nbsp;{{ item?.code }} -
                    {{ $t("barcode") + ": " + item?.barcode }}
                  </p>
                </div>
                <v-text-field
                  class="flex-grow-0 ml-auto"
                  style="max-width: 150px"
                  outlined
                  dense
                  hide-details
                  type="number"
                  hide-spin-buttons
                  :label="$t('quantity')"
                  v-model="item.qte_in_bundle"
                  :rules="[]"
                >
                  <template v-slot:append-outer>
                    <v-icon
                      color="red"
                      @click="toggleAddedProduct(item)"
                    >
                      mdi-delete
                    </v-icon>
                  </template>
                </v-text-field>
              </div>
            </div>
          </div>
        </template>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            @click="addBundleItems"
            medium
            filled
            color="primary"
          >
            {{
              $t("add_n_items_to_bundle", {
                count: this.editedBundleItems?.length ?? 0,
              })
            }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>
<script>
import { mapGetters } from "vuex";
import { helpers } from "@/mixins/helpers";
import debounce from "lodash/debounce";

export default {
  props: {
    updatePrices: Function,
  },
  mixins: [helpers],
  computed: {
    ...mapGetters({
      leafCategories: "categories/listLeafs",
      isLoadingCategories: "categories/isLoadingCategories",
      isLoadingProducts: "products/isLoadingProducts",
      listProducts: "products/listProducts",
      productsMeta: "products/meta",
      editedBundleItems: "packsAndBundles/editedBundleItems",
      selectedCategory: "packsAndBundles/selectedCategory",
    }),
    editedBundleItems: {
      get() {
        return this.$store.state.packsAndBundles.editedBundleItems;
      },
      set(items) {
        this.$store.commit("packsAndBundles/SET_EDITED_BUNDLE_ITEMS", items);
      },
    },
    isVisibleEditBundleItemsDialog: {
      get() {
        return this.$store.state.packsAndBundles.isVisibleEditBundleItemsDialog;
      },
      set(value) {
        this.$store.commit(
          "packsAndBundles/IS_VISIBLE_EDIT_BUNDLE_ITEMS_DIALOG",
          value
        );
      },
    },
  },
  watch: {
    options: {
      deep: true,
      handler: debounce(async function () {
        this.is_loading_product = true;
        await this.$store.dispatch("products/root", this.options).then(() => {
          this.is_loading_product = false;
        });
      }, 1000),
    },
    selectedCategory(value) {
      this.options.category_id = value;
    },
  },
  data() {
    return {
      search: "",
      options: { itemsPerPage: 10, qte: 1 },
      is_loading_product: false,
      headers: [
        {
          text: this.$t("image"),
          align: "start",
          value: "image",
          sortable: false,
        },
        {
          text: this.$t("product_name"),
          align: "start",
          sortable: true,
          value: "name",
        },
        {
          text: this.$t("product_id"),
          align: "start",
          sortable: true,
          value: "code",
        },
        {
          text: this.$t("sku"),
          align: "start",
          sortable: true,
          value: "sku",
        },
        {
          text: this.$t("price"),
          value: "price",
          sortable: false,
        },
        {
          text: this.$t("stock"),
          value: "qte",
        },

        {
          text: this.$t("moving"),
          value: "qte",
          sortable: true,
        },
        {
          text: this.$t("actions"),
          value: "actions",
          sortable: false,
        },
      ],
    };
  },
  methods: {
    closeForm() {
      this.editedBundleItems = this.editedBundleItems.filter(
        (item) => item.qte_in_bundle > 0
      );
      this.$store.dispatch("packsAndBundles/closeForm");
    },
    toggleAddedProduct(product) {
      const found = this.editedBundleItems?.find(
        (item) => item.id === product.id
      );
      if (found) {
        this.editedBundleItems.splice(
          this.editedBundleItems.indexOf(product),
          1
        );
      } else if (product.qte > 0) {
        this.editedBundleItems.push({
          qte_in_bundle: 1,
          ...product,
        });
      } else {
        this.$swal("", this.$t("min_bundle_qte",{qte:product?.qte ?? 0}), "error");
      }
    },
    isItemSelected(product) {
      return !!this.editedBundleItems?.find((item) => item.id === product.id);
    },
    AddedItemsAreValid() {
      if (!this.editedBundleItems?.length) return false;
      return !this.editedBundleItems?.find(
        (item) => item?.qte_in_bundle < 1 || item?.qte_in_bundle > item?.qte
      );
    },
    async addBundleItems() {
      await this.$store.dispatch(
        "packsAndBundles/addBundleItems",
        this.editedBundleItems
      );
      this.updatePrices();
    },
  },
};
</script>